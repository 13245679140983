@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --font-sans: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    --font-mono: "IBM Plex Mono", monospace;
  }
  
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    overscroll-behavior-y: none;
    overflow-x: hidden;
    font-family: var(--font-sans);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::selection {
    @apply bg-primary text-primary-foreground;
  }
  
}

/* ---------------------------------- */
/* Classic Light
/* ---------------------------------- */

.light {
  --black: #000;
  --white: #fff;

  --background: #ffffff;
  --foreground: #000000;

  --muted: #777;
  --muted-foreground: #000000;

  --popover: var(--card);
  --popover-foreground: var(--background);

  --card: #ececec;
  --card-foreground: #000000;

  --border: #000000;
  --border-light: #c0c0c0;

  --input: var(--card);

  --primary: #000;
  --primary-foreground: #fff;

  --secondary: #fff;
  --secondary-foreground: #ffffff;

  --accent: #f9f9f9;
  --accent-foreground: #3c536f;

  --destructive: #ef4444ff;
  --destructive-foreground: #f8fafcff;

  --ring: #93c5fd99;

  --radius: 0;

  --skeleton: #dbdee2;
}

/* ---------------------------------- */

.dark {
  --black: #000;
  --white: #fff;

  --background: #040609;
  --foreground: #64758a;

  --muted: #64758aa0;
  --muted-foreground: #aac2dd;

  --popover: #040609;
  --popover-foreground: var(--card);

  --card: #121620;
  --card-foreground: var(--foreground);

  --border: #2a323b;
  --border-light: #2a323ba8;

  --input: var(--card);

  --primary: var(--muted-foreground);
  --primary-foreground: #052c4b;

  --secondary: #000000;
  --secondary-foreground: var(--muted-foreground);

  --accent: #080a0e;
  --accent-foreground: #80b0e399;

  --destructive: #ef4444ff;
  --destructive-foreground: #f2d8d8;

  --ring: var(--muted-foreground);

  --radius: 0;

  --skeleton: #2a2929;
}

/* ---------------------------------- */
/* Paper Light
/* ---------------------------------- */

.paper-light {
  --black: #000;
  --white: #fff;

  --background: #f5f5f1;
  --foreground: #737373;

  --muted: #999;
  --muted-foreground: #000000;

  --popover: #ffffff;
  --popover-foreground: var(--background);

  --card: #ffffff;
  --card-foreground: #000000;

  --border: #d2d2d2;
  --border-light: #dadada;

  --input: var(--card);

  --primary: #3c536f;
  --primary-foreground: #ffffff;

  --secondary: #ffffff;
  --secondary-foreground: var(--muted);

  --accent: #f9f9f9;
  --accent-foreground: var(--primary);

  --destructive: rgb(175, 50, 50);
  --destructive-foreground: #f8fafcff;

  --ring: #93c5fd99;

  --radius: 6px;

  --skeleton: #eeecea;
}

/* ---------------------------------- */
/* Paper Dark
/* ---------------------------------- */

.paper-dark {
  --black: #000;
  --white: #fff;

  --background: #071017;
  --foreground: #8ea0ae;

  --muted: #485a69;
  --muted-foreground: #bcd1e2;

  --popover: #071017;
  --popover-foreground: var(--accent);

  --card: #0e1922;
  --card-foreground: #000000;

  --border: #253441;
  --border-light: #192630;

  --input: var(--card);

  --primary: #caaf38;
  --primary-foreground: #2a2303;

  --secondary: #ffffff;
  --secondary-foreground: var(--muted);

  --accent: #040b11;
  --accent-foreground: var(--primary);

  --destructive: rgb(175, 50, 50);
  --destructive-foreground: #f8fafcff;

  --ring: #93c5fd99;

  --radius: 6px;

  --skeleton: #eeecea;
}

/* ---------------------------------- */
/* Modern Dark
/* ---------------------------------- */

.modern-dark {
  --black: #000;
  --white: #fff;

  --background: #040609;
  --foreground: #a3adb9;

  --muted: #8c9fb7a0;
  --muted-foreground: #dde5ee;

  --popover: #040609;
  --popover-foreground: var(--card);

  --card: #13151a;
  --card-foreground: var(--foreground);

  --border: #1f2328;
  --border-light: #2a323b7e;

  --input: var(--card);

  --primary: #59b4ff;
  --primary-foreground: #052c4b;

  --secondary: #000000;
  --secondary-foreground: var(--muted-foreground);

  --accent: #080a0e;
  --accent-foreground: #80b0e399;

  --destructive: #ef4444ff;
  --destructive-foreground: #f2d8d8;

  --ring: var(--muted-foreground);

  --radius: 12px;

  --skeleton: #2a2929;
}

/* ---------------------------------- */
/* Modern Light
/* ---------------------------------- */

.modern-light {
  --black: #000;
  --white: #fff;

  --background: #ffffff;
  --foreground: #2f2f2f;

  --muted: #999;
  --muted-foreground: #000000;

  --popover: #ffffff;
  --popover-foreground: var(--card);

  --card: #f1f1f1;
  --card-foreground: #000000;

  --border: #ccc;
  --border-light: #dadada;

  --input: var(--card);

  --primary: #0062ff;
  --primary-foreground: #ffffff;

  --secondary: #ffffff;
  --secondary-foreground: #000;

  --accent: #f9f9f9;
  --accent-foreground: #3c536f;

  --destructive: #ef4444ff;
  --destructive-foreground: #f8fafcff;

  --ring: #93c5fd99;

  --radius: 12px;

  --skeleton: #dbdee2;
}

/* ---------------------------------- */
/* radar Dark
/* ---------------------------------- */
.radar-dark {
  --black: #8ccf95;
  --white: #ffffff;

  --background: #080f0a;
  --foreground: #728275;

  --muted: #728275;
  --muted-foreground: #adc7b2;

  --popover: #000000;
  --popover-foreground: #243026;

  --card: #182119;
  --card-foreground: #827f72;

  --border: #314234;
  --border-light: #72827539;

  --input: #182119;

  --primary: #acc7b3;
  --primary-foreground: #1b2e1f;

  --secondary: #827f72;
  --secondary-foreground: #827f72;

  --accent: #141d16;
  --accent-foreground: #827f72;

  --destructive: #fd9393;
  --destructive-foreground: #fd9393;

  --ring: #141d16;
}


/* ---------------------------------- */
/* radar Light
/* ---------------------------------- */
.radar-light {
  --black: #cf8c8c;
  --white: #ffffff;

  --background: #0f0808;
  --foreground: #827272;

  --muted: #827272;
  --muted-foreground: #c7adad;

  --popover: #000000;
  --popover-foreground: #302424;

  --card: #211818;
  --card-foreground: #827272;

  --border: #423131;
  --border-light: #82727239;

  --input: #211818;

  --primary: #c7acac;
  --primary-foreground: #2e1b1b;

  --secondary: #827272;
  --secondary-foreground: #827272;

  --accent: #1d1414;
  --accent-foreground: #827272;

  --destructive: #fd9393;
  --destructive-foreground: #fd9393;

  --ring: #1d1414;
}
/* ---------------------------------- */
/* Custom Scrollbar */
/* ---------------------------------- */
.openfund-scrollbar::-webkit-scrollbar {
  width: 0.3rem;
  height: 3px;
}

.openfund-scrollbar::-webkit-scrollbar-track {
  background: var(--card);
}

.openfund-scrollbar::-webkit-scrollbar-thumb {
  background: var(--primary);
  border: 1px solid transparent;
  border-radius: var(--radius);
}


/* ---------------------------------- */
/* Font
/* ---------------------------------- */
.font-mono {
  font-family: var(--font-mono) !important;
}

.font-sans {
  font-family: var(--font-sans) !important;
}

.dark .font-shadow-green,
.modern-dark .font-shadow-green,
.paper-dark .font-shadow-green {
  text-shadow: 0 0 20px #17a34a;
}

/* ---------------------------------- */
/* Theme: Paper Light
/* ---------------------------------- */
.paper-dark .ProseMirror p,
.paper-dark .ProseMirror ol,
.paper-dark .ProseMirror ul,
.paper-dark .ProseMirror li {
  @apply text-[15px] leading-[25px];
}

.paper-dark button[data-state="checked"] {
  background-color: var(--accent-foreground);
}

.paper-dark .text-green-600 {
  @apply text-green-500;
}

.paper-dark *:not(.unforce-radius),
* .paper-dark *:not(.unforce-radius)::before,
.paper-dark *:not(.unforce-radius)::after {
  border-radius: 4px !important;
}

.rounded-full.rounded-force {
  border-radius: 9999px !important;
}

/* ---------------------------------- */
/* Theme: Paper Dark
/* ---------------------------------- */

.paper-light .ProseMirror p,
.paper-light .ProseMirror ol,
.paper-light .ProseMirror ul,
.paper-light .ProseMirror li {
  @apply text-[15px] leading-[26px];
}

.paper-light button[data-state="checked"] {
  background-color: var(--accent-foreground);
}

.paper-light *,
* .paper-light *::before,
.paper-light *::after {
  border-radius: 4px !important;
}

/* ---------------------------------- */
/* Theme: Modern Dark
/* ---------------------------------- */
.modern-dark body,
.modern-dark .font-sans,
.modern-light body,
.modern-light .font-sans {
  font-family: var(--font-sans) !important;
}

.modern-dark button[data-element="button"] {
  border-radius: 100px;
}

.modern-dark .text-green-600 {
  @apply text-green-500;
}

/* ---------------------------------- */
/* Theme: Modern Light
/* ---------------------------------- */
.modern-light body,
.modern-light .font-sans {
  font-family: var(--font-sans) !important;
}

.modern-light button[data-element="button"] {
  border-radius: 100px;
}

/* ---------------------------------- */
/* Theme: radar Dark
/* ---------------------------------- */
.radar-dark body {
  font-family: var(--font-mono);
}

.radar-dark .font-sans {
  font-family: var(--font-sans);
}

.radar-dark .text-green-600 {
  @apply text-green-500;
}

.radar-dark *:not(.unforce-radius),
.radar-dark *:not(.unforce-radius)::before,
.radar-dark *:not(.unforce-radius)::after {
  border-radius: 0 !important;
}

.radar-dark button.button-link {
  @apply !font-mono;
}

.radar-dark .text-muted-foreground {
  text-shadow: 2px 0px 10px #007916;
}

.radar-dark body {
  background-color: #000000;
  background-image: radial-gradient(#070b08, #040904), url("/public/images/noise.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: inset 0 0 100px black;
}

.radar-dark .noise {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("/public/images/noise.gif");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  opacity: .02;
}

.radar-dark .overlay {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background:
      repeating-linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0,
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0) 100%);
  background-size: auto 4px;
  z-index: -1;
}

.radar-dark .overlay::before {
  content: "";
  pointer-events: none;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      0deg,
      transparent 0%,
      rgba(32, 128, 32, 0.2) 2%,
      rgba(32, 128, 32, 0.4) 3%,
      rgba(32, 128, 32, 0.2) 3%,
      transparent 100%);
  background-repeat: no-repeat;
  animation: scan 14s linear 0s infinite;
}

@keyframes scan {
  0%        { background-position: 0 -100vh; }
  35%, 100% { background-position: 0 100vh; }
}

/* ---------------------------------- */
/* Theme: radar Light
/* ---------------------------------- */
.radar-light body {
  font-family: var(--font-mono);
}

.radar-light .font-sans {
  font-family: var(--font-sans);
}

.radar-light .text-green-600 {
  @apply text-green-500;
}

.radar-light *:not(.unforce-radius),
.radar-light *:not(.unforce-radius)::before,
.radar-light *:not(.unforce-radius)::after {
  border-radius: 0 !important;
}

.radar-light button.button-link {
  @apply !font-mono;
}

.radar-light .text-muted-foreground {
  text-shadow: 2px 0px 10px #790000;
}

.radar-light body {
  background-color: #000000;
  background-image: radial-gradient(#0b0707, #090404), url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: inset 0 0 100px black;
}

.radar-light .noise {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  opacity: .02;
}

.radar-light .overlay {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background:
      repeating-linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0,
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0) 100%);
  background-size: auto 4px;
  z-index: -1;
}

.radar-light .overlay::before {
  content: "";
  pointer-events: none;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      0deg,
      transparent 0%,
      rgba(128, 32, 32, 0.2) 2%,
      rgba(128, 32, 32, 0.4) 3%,
      rgba(128, 32, 32, 0.2) 3%,
      transparent 100%);
  background-repeat: no-repeat;
  animation: scan 14s linear 0s infinite;
}

@keyframes scan {
  0%        { background-position: 0 -100vh; }
  35%, 100% { background-position: 0 100vh; }
}

/* ---------------------------------- */
/* Theme: Classic Dark
/* ---------------------------------- */
.dark body {
  font-family: var(--font-mono);
}

.dark .font-sans {
  font-family: var(--font-sans);
}

.dark .text-green-600 {
  @apply text-green-500;
}

.dark *:not(.unforce-radius),
.dark *:not(.unforce-radius)::before,
.dark *:not(.unforce-radius)::after {
  border-radius: 0 !important;
}

.dark h1[data-element="detail-headline"] {
  font-size: 26px;
}

.dark button.button-link {
  @apply !font-mono;
}

/* ---------------------------------- */
/* Theme: Classic Light
/* ---------------------------------- */
.light body {
  font-family: var(--font-mono);
}

.light .font-sans {
  font-family: var(--font-sans);
}

.light *:not(.unforce-radius),
.light *:not(.unforce-radius)::before,
.light *:not(.unforce-radius)::after {
  border-radius: 0 !important;
}

.light h1[data-element="detail-headline"] {
  font-size: 26px;
}

.light .ProseMirror a {
  @apply underline underline-offset-4;
}

.light button.button-link {
  @apply !font-mono;
}

/* ---------------------------------- */
/* Input: Number
/* ---------------------------------- */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

/* ---------------------------------- */
/* Font
/* ---------------------------------- */
.font-mono {
  font-family: var(--font-mono);
}

.light .text-green-500, .paper-light .text-green-500, .modern-light .text-green-500, .classic-light .text-green-500 {
  @apply !text-green-600;
}
